body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.cards{
  display: block;
}

.cards > * {
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid;
  overflow: unset;
}

.card{
    margin: 0 auto;
}

@media (min-width: 770px) {
  .cards {
    -webkit-column-count: 2;
            column-count: 2;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
  }
}

@media (min-width: 992px) {
  .cards {
    -webkit-column-count: 3;
            column-count: 3;
  }
}
@media (min-width: 1200px) {
    .cards {
        -webkit-column-count: 4;
                column-count: 4;
    }
}

.addPlanetBtn{
  -webkit-transition: 500ms all;
  transition: 500ms all;
}

.submitPlanet {
    /* transition: 5s transform; */
    -webkit-animation: fadePlanet 1s 2s forwards, shootPlanet 3s ease-in;
            animation: fadePlanet 1s 2s forwards, shootPlanet 3s ease-in;
    z-index: 5;
}

@-webkit-keyframes fadePlanet {
    from {opacity: 1;}
    to {opacity: 0;}
}

@keyframes fadePlanet {
    from {opacity: 1;}
    to {opacity: 0;}
}

@-webkit-keyframes shootPlanet {
    0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);}
    10%{
    -webkit-transform: translateX(-200px) rotate(10deg);
            transform: translateX(-200px) rotate(10deg);
    }
    100% {
    -webkit-transform: translateX(2000px) rotate(-70deg) translateY(-500px);
            transform: translateX(2000px) rotate(-70deg) translateY(-500px);
  }
}

@keyframes shootPlanet {
    0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);}
    10%{
    -webkit-transform: translateX(-200px) rotate(10deg);
            transform: translateX(-200px) rotate(10deg);
    }
    100% {
    -webkit-transform: translateX(2000px) rotate(-70deg) translateY(-500px);
            transform: translateX(2000px) rotate(-70deg) translateY(-500px);
  }
}
#loader{
    height: 60vh;
    width: 100%;
    position: relative;
}
#earth {
    background-image: url('https://encrypted-tbn3.google.com/images?q=tbn:ANd9GcTQqoB4xBnIkmUg5pxDyYW2h0Q1pRTsbQBOfhg-E-y4iLFicsyi');
    width: 150px;
    height: 150px;
    /* a black shadow from left and white from right */
    box-shadow: inset 16px 0 40px 3px rgba(0,0,0,0.9),
    inset -3px 0 5px 2px rgba(255,255,255,0.16);
    background-size: 190px;
    margin: 150px auto 80px;
    border-radius: 50%;
    position: relative;
    -webkit-animation-name: move,scale,rotate;
            animation-name: move,scale,rotate;
    -webkit-animation-duration: 4s,4s,4s;
            animation-duration: 4s,4s,4s;
    -webkit-animation-iteration-count: infinite,infinite,infinite;
            animation-iteration-count: infinite,infinite,infinite;
    -webkit-animation-timing-function: ease-in-out,linear,linear;
            animation-timing-function: ease-in-out,linear,linear;
}

@-webkit-keyframes move {
    0%   { left: 200px;  }
    70%  { left: -200px; }
    100% { left: 200px;  }
}

@keyframes move {
    0%   { left: 200px;  }
    70%  { left: -200px; }
    100% { left: 200px;  }
}

@-webkit-keyframes scale {
    0%  { -webkit-transform: scale(1); transform: scale(1);   }
    32% { -webkit-transform: scale(0.4); transform: scale(0.4); -webkit-animation-timing-function:  ease-in; animation-timing-function:  ease-in; }
    70% { -webkit-transform: scale(1); transform: scale(1); -webkit-animation-timing-function:  ease-in; animation-timing-function:  ease-in;  }
    75% { -webkit-transform: scale(1.2); transform: scale(1.2);  -webkit-animation-timing-function:  ease-in-out;  animation-timing-function:  ease-in-out; }
    86% { -webkit-transform: scale(2); transform: scale(2);  }
    98% { -webkit-transform: scale(1.2); transform: scale(1.2); }
    100%{ -webkit-transform: scale(1); transform: scale(1); }
}

@keyframes scale {
    0%  { -webkit-transform: scale(1); transform: scale(1);   }
    32% { -webkit-transform: scale(0.4); transform: scale(0.4); -webkit-animation-timing-function:  ease-in; animation-timing-function:  ease-in; }
    70% { -webkit-transform: scale(1); transform: scale(1); -webkit-animation-timing-function:  ease-in; animation-timing-function:  ease-in;  }
    75% { -webkit-transform: scale(1.2); transform: scale(1.2);  -webkit-animation-timing-function:  ease-in-out;  animation-timing-function:  ease-in-out; }
    86% { -webkit-transform: scale(2); transform: scale(2);  }
    98% { -webkit-transform: scale(1.2); transform: scale(1.2); }
    100%{ -webkit-transform: scale(1); transform: scale(1); }
}

@-webkit-keyframes rotate {
    0% { background-position: 0px; }
    100% { background-position: 190px; }
}

@keyframes rotate {
    0% { background-position: 0px; }
    100% { background-position: 190px; }
}

