#loader{
    height: 60vh;
    width: 100%;
    position: relative;
}
#earth {
    background-image: url('https://encrypted-tbn3.google.com/images?q=tbn:ANd9GcTQqoB4xBnIkmUg5pxDyYW2h0Q1pRTsbQBOfhg-E-y4iLFicsyi');
    width: 150px;
    height: 150px;
    /* a black shadow from left and white from right */
    box-shadow: inset 16px 0 40px 3px rgba(0,0,0,0.9),
    inset -3px 0 5px 2px rgba(255,255,255,0.16);
    background-size: 190px;
    margin: 150px auto 80px;
    border-radius: 50%;
    position: relative;
    animation-name: move,scale,rotate;
    animation-duration: 4s,4s,4s;
    animation-iteration-count: infinite,infinite,infinite;
    animation-timing-function: ease-in-out,linear,linear;
}

@keyframes move {
    0%   { left: 200px;  }
    70%  { left: -200px; }
    100% { left: 200px;  }
}

@keyframes scale {
    0%  { transform: scale(1);   }
    32% { transform: scale(0.4); animation-timing-function:  ease-in; }
    70% { transform: scale(1); animation-timing-function:  ease-in;  }
    75% { transform: scale(1.2);  animation-timing-function:  ease-in-out; }
    86% { transform: scale(2);  }
    98% { transform: scale(1.2); }
    100%{ transform: scale(1); }
}

@keyframes rotate {
    0% { background-position: 0px; }
    100% { background-position: 190px; }
}
