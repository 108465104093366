body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.cards{
  display: block;
}

.cards > * {
  break-inside: avoid;
  overflow: unset;
}

.card{
    margin: 0 auto;
}

@media (min-width: 770px) {
  .cards {
    column-count: 2;
    column-gap: 20px;
  }
}

@media (min-width: 992px) {
  .cards {
    column-count: 3;
  }
}
@media (min-width: 1200px) {
    .cards {
        column-count: 4;
    }
}

.addPlanetBtn{
  transition: 500ms all;
}

.submitPlanet {
    /* transition: 5s transform; */
    animation: fadePlanet 1s 2s forwards, shootPlanet 3s ease-in;
    z-index: 5;
}

@keyframes fadePlanet {
    from {opacity: 1;}
    to {opacity: 0;}
}

@keyframes shootPlanet {
    0% {
    transform: translateX(0);}
    10%{
    transform: translateX(-200px) rotate(10deg);
    }
    100% {
    transform: translateX(2000px) rotate(-70deg) translateY(-500px);
  }
}